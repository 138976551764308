import { Switch, Route, Redirect } from "react-router-dom";

import { useTrackPageView } from "./hooks/useTrackPageView";
import { useAnalyticIdentifyUser } from "./hooks/useAnalyticIdentifyUser";

import PrivateRoute from "src/components/PrivateRoute/PrivateRoute";

import { NotFound } from "src/components/pages/Error/NotFound";
import { BillingDetails } from "src/components/pages/Billing/BillingDetails";
import { BusinessBillingDetails } from "./components/pages/Billing/BusinessBillingDetails";

import BusinessBase from "./components/Business/Base";
import RedirectToFirstBusiness from "src/components/Business/RedirectToFirstBusiness";
import BusinessParentAndChildSettings from "src/components/Business/AccountHierarchy";

import { AcceptBusinessInvitation } from "./app/business-invitation/page";
import { AcceptBusinessChildrenInvitation } from "./app/business-child-invitation/page";
import { Account } from "./app/account/page";
import { BusinessHome } from "./app/business/[businessId]/page";
import { ConfirmChildAttachment } from "./app/confirm-child-attachment/page";
import { ConnectedAcounts } from "./app/connected-accounts/page";
import { Emails } from "./app/emails/page";
import { Root } from "./app/page";
import { Home } from "./app/home/page";
import { Profile } from "./app/profile/page";

import BusinessAuth from "./app/business/[businessId]/auth/page";
import BusinessFeatureFlagSettings from "./app/business/[businessId]/feature-flag-settings/page";
import BusinessProfile from "./app/business/[businessId]/profile/page";
import BusinessDomainMatching from "./app/business/[businessId]/domain-matching/page";
import BusinessUsers from "./app/business/[businessId]/users/page";
import UpdateBusinessSubscription from "./app/business/[businessId]/subscriptions/[subscriptionId]/page";
import { activateProTrialPath, keycloakRealmName, legacyKeycloakIframePath } from "./constants";
import { ActivateProTrialPage } from "./app/subscribe/pro-trial/activate/page";
import { RedirectToIdpLogIn } from "./app/signin/page";
import { RedirectToIdpRegistration } from "./app/signup/page";
import { SubscribePage } from "./app/subscribe/page";
import { ProTrialSignUpPage } from "./app/subscribe/pro-trial/signup/page";
import { DiscourseSSO } from "./app/forums/page";
import { ZendeskSSO } from "./app/support/page";
import { RedirectToLensDesktop } from "./app/logged-in/page";
import { Activate } from "./app/subscriptions/activate/page";
import { SpaceInvitation } from "./app/space-invitation/page";
import { LensLauncher } from "./app/lens-launcher/page";
import { VerifySecondaryEmail } from "./app/verify-secondary-email/page";

export const Routes = () => {
  useTrackPageView();
  useAnalyticIdentifyUser();

  return (
    <Switch>
      <Route path="/signin" exact>
        <Redirect to="/" />
      </Route>
      <Route path="/signup" exact>
        <Redirect to="/" />
      </Route>
      <Route path="/lens-launcher">
        <LensLauncher />
      </Route>
      <Route path="/verify-secondary-email" exact>
        <VerifySecondaryEmail />
      </Route>
      <Route path="/confirm-child-attachment" exact>
        <ConfirmChildAttachment />
      </Route>
      <Route path={`/${legacyKeycloakIframePath}`}>
        <RedirectToLensDesktop />
      </Route>
      <Route path={`/signin/realms/${keycloakRealmName}/protocol/openid-connect/auth`}>
        <RedirectToIdpLogIn />
      </Route>
      <Route path={`/signup/realms/${keycloakRealmName}/protocol/openid-connect/auth`}>
        <RedirectToIdpRegistration />
      </Route>
      <PrivateRoute path={"/subscribe/pro-trial/signup"} exact>
        <ProTrialSignUpPage />
      </PrivateRoute>
      <PrivateRoute path={activateProTrialPath} exact>
        <ActivateProTrialPage />
      </PrivateRoute>
      <Route path={"/subscribe"} exact>
        <SubscribePage />
      </Route>
      <PrivateRoute path={"/subscriptions/activate"} exact>
        <Activate />
      </PrivateRoute>
      <PrivateRoute path="/business-invitation" exact>
        <AcceptBusinessInvitation />
      </PrivateRoute>
      <PrivateRoute path={"/billing-details"} exact>
        <BillingDetails />
      </PrivateRoute>
      <PrivateRoute path="/home" exact>
        <Home />
      </PrivateRoute>
      <PrivateRoute path="/business" exact>
        <RedirectToFirstBusiness />
      </PrivateRoute>
      <PrivateRoute path="/business/:businessId/subscriptions/:subscriptionId" exact>
        <UpdateBusinessSubscription />
      </PrivateRoute>
      <Route path={"/business/:businessId"} exact={false}>
        <BusinessBase>
          <Switch>
            <PrivateRoute path="/business/:businessId" exact>
              <BusinessHome />
            </PrivateRoute>
            <PrivateRoute path="/business/:businessId/parent-child-settings" exact>
              <BusinessParentAndChildSettings />
            </PrivateRoute>
            <PrivateRoute path="/business/:businessId/feature-flag-settings" exact>
              <BusinessFeatureFlagSettings />
            </PrivateRoute>
            <PrivateRoute path="/business/:businessId/profile" exact>
              <BusinessProfile />
            </PrivateRoute>
            <PrivateRoute path="/business/:businessId/domain-matching" exact>
              <BusinessDomainMatching />
            </PrivateRoute>
            <PrivateRoute path="/business/:businessId/account" exact>
              {({ match }) => {
                return <Redirect to={`/business/${match?.params.businessId}/profile`} />;
              }}
            </PrivateRoute>
            <PrivateRoute path="/business/:businessId/auth" exact>
              <BusinessAuth />
            </PrivateRoute>
            <PrivateRoute path="/business/:businessId/billing-details" exact>
              <BusinessBillingDetails />
            </PrivateRoute>
            <PrivateRoute path="/business/:businessId/users" exact={false}>
              <BusinessUsers />
            </PrivateRoute>
            <PrivateRoute>
              {({ match }) => {
                return <Redirect to={`/business/${match?.params.businessId}`} />;
              }}
            </PrivateRoute>
          </Switch>
        </BusinessBase>
      </Route>
      <PrivateRoute path="/support">
        <ZendeskSSO />
      </PrivateRoute>
      <PrivateRoute path="/forums">
        <DiscourseSSO />
      </PrivateRoute>
      <PrivateRoute path={"/business-child-invitation"} exact>
        <AcceptBusinessChildrenInvitation />
      </PrivateRoute>
      <PrivateRoute path="/space-invitation" exact>
        <SpaceInvitation />
      </PrivateRoute>
      <PrivateRoute path="/profile">
        <Profile />
      </PrivateRoute>
      <PrivateRoute path="/account">
        <Account />
      </PrivateRoute>
      <PrivateRoute path="/connected-accounts">
        <ConnectedAcounts />
      </PrivateRoute>
      <PrivateRoute path="/emails">
        <Emails />
      </PrivateRoute>
      <PrivateRoute path="/" exact>
        <Root />
      </PrivateRoute>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};
