import { useCallback, useContext, useMemo } from "react";
import { useConfirmDialog } from "@k8slens/lds";

import { useGetBusinessInvoices } from "src/hooks/useGetBusinessInvoices";
import { useGetBusinessSubscriptions } from "src/hooks/useGetBusinessSubscriptions";
import { useBusinessBillingPageToken } from "src/hooks/useBillingPageToken";
import { useGetBusinessBillingInformation } from "src/hooks/useGetBusinessBillingInfo";
import { useBusinessBillingPageUrl } from "src/hooks/useBillingPageUrl";
import { useBusinessChildren } from "src/hooks/useBusinessChildren";
import { PlanCode, isLensProPlan } from "src/services/plans";
import { openUrl } from "src/utils/url";
import { checkoutBaseUrl, pricingUrl } from "src/constants";

import { BusinessContext } from "src/components/Business/Base";
import { AutoJoinSwitch } from "src/components/Business/AutoJoinSwitch";
import PageHeader from "src/components/PageHeader/PageHeader";
import PageSection from "src/components/PageSection/PageSection";
import PageAction from "src/components/PageAction/PageAction";

import { Invoices } from "./Invoices";
import { Billing } from "./Billing";
import { BillingSubscriptions } from "./BillingSubscriptions";

export const BusinessBillingDetails = () => {
  const { businessId, business } = useContext(BusinessContext);
  const { invoices, loading } = useGetBusinessInvoices(businessId);
  const { businessSubscriptions } = useGetBusinessSubscriptions(businessId, true);
  const lensProSubscriptions = useMemo(
    () => businessSubscriptions.filter(({ planCode, state }) => isLensProPlan(planCode) && state !== "expired"),
    [businessSubscriptions],
  );
  const { businessChildren } = useBusinessChildren(businessId);
  const { token } = useBusinessBillingPageToken(businessId);
  const { billingInfo } = useGetBusinessBillingInformation(businessId);
  const { billingInfoUrl } = useBusinessBillingPageUrl(businessId);
  const showNewSubscriptionButton = Boolean(business?.reseller);

  const showReactivateSubscriptionButton =
    !showNewSubscriptionButton &&
    businessSubscriptions.length &&
    businessSubscriptions.every((s) => s.state === "expired");
  const { ConfirmDialog, confirm } = useConfirmDialog();
  const handleNewSubscriptions = useCallback(async () => {
    const planCode: PlanCode = "pro-yearly";

    if (lensProSubscriptions.length > 0) {
      const confirmed = await confirm(
        "Create New Subscription?",
        <p>
          Your Lens Business ID already has a Lens Pro subscription associated with it.
          {/* eslint-disable-next-line max-len */}
          If you wish to purchase more seats you may{" "}
          <strong>modify the existing subscription to increase the number of seats</strong>. You can read more about
          adding and changing Lens subscriptions{" "}
          <a
            className="primary"
            target="_blank"
            href="https://docs.k8slens.dev/lens-id/lens-business-id/subscriptions/add-subscriptions/"
            rel="noreferrer"
          >
            here
          </a>
          .<p></p>
          Are you sure you want to create new subscription?
        </p>,
        { confirmText: "Create" },
      );

      if (!confirmed) {
        return;
      }
    }

    openUrl(`${checkoutBaseUrl}/subscribe/${planCode}/${businessId}`, true);
  }, [businessId, confirm, lensProSubscriptions.length]);

  return (
    <section>
      <PageHeader
        title="Billing details"
        subtitle={
          <>
            Manage your Lens Business ID <strong>{`(${business?.id})`}</strong> billing, subscriptions and payment
            methods here.
          </>
        }
      />
      <PageSection>
        <PageHeader type="sub" title="Payment method" subtitle="Manage payment method for your Lens Subscriptions." />
        <Billing billingInfo={billingInfo} billingInfoUrl={billingInfoUrl} />
      </PageSection>
      <PageSection>
        <PageHeader type="sub" title="Subscriptions" subtitle="Manage your Lens subscriptions." />
        <BillingSubscriptions
          businessChildren={businessChildren}
          subscriptions={businessSubscriptions}
          billingPageToken={token}
          loading={loading}
        />
        {ConfirmDialog}
        {showNewSubscriptionButton ? (
          <PageAction
            label={"Create New Subscription"}
            buttonProps={{
              discreet: true,
              onClick: () => handleNewSubscriptions(),
            }}
          />
        ) : null}
        {showReactivateSubscriptionButton ? (
          <PageAction
            label={"Reactivate Subscription"}
            buttonProps={{
              discreet: true,
              href: `${pricingUrl}?businessId=${businessId}`,
              disabled: Boolean(business?.external),
              title: business?.external
                ? "Cannot reactivate subscription for this account. Please contact your sales representative to reactivate this subscription."
                : "",
            }}
          />
        ) : null}
      </PageSection>
      <PageSection>
        <AutoJoinSwitch />
      </PageSection>
      {business?.external ? null : (
        <PageSection>
          <PageHeader
            title="Billing history"
            subtitle="Review you billing history and manage your invoices."
            type="sub"
          />
          <Invoices
            businessChildren={businessChildren}
            billingPageToken={token}
            invoices={invoices}
            loading={loading}
          />
        </PageSection>
      )}
    </section>
  );
};
