import { useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { FormInput } from "@k8slens/lds-form";
import { Notification } from "@k8slens/lds";
import { FormSwitchGroup } from "@k8slens/lds-form";
import { type SwitchValue } from "@k8slens/lds-form/lib/es/FormSwitchGroup/FormSwitchGroup";

import { useUploadAvatar } from "src/hooks/useUploadAvatar";
import { ProfileContext } from "src/providers/profile-provider";
import { useLensPlatformClient } from "src/hooks/useLensPlatformClient";
import { useAnalytics } from "src/hooks/useAnalytics";
import { useUserCommunicationPreferences } from "src/hooks/useUserCommunicationPreferences";

import Layout from "src/components/Layout/Layout";
import PageHeader from "src/components/PageHeader/PageHeader";
import PageSection from "src/components/PageSection/PageSection";
import PageAction from "src/components/PageAction/PageAction";
import AvatarFormComponent from "src/components/AvatarFormComponent/AvatarFormComponent";

import styles from "./page.module.css";

type FieldValues = {
  firstName: { value: string; valid?: boolean };
  lastName: { value: string; valid?: boolean };
  company: { value: string; valid?: boolean };
};

type ReducerAction<Key extends keyof FieldValues> = {
  key: Key;
  value: FieldValues[Key]["value"];
  valid: boolean;
};

const valueReducer = (state: FieldValues, { key, ...value }: ReducerAction<keyof FieldValues>) => ({
  ...state,
  [key]: value,
});

const emailCommunicationTitle = "Email Communication Preferences";
const emailCommunicationDescription = "Here you can change your communication preferences.";

const onboardingLabel = "Lens Product Information";
const onboardingDescription =
  "Receive product and service updates, news, best practices, and onboarding assistance from Mirantis, Inc. about Lens family products and services.";

const marketingLabel = "Mirantis Marketing Email Communications";
const marketingDescription = "Receive marketing communications from Mirantis, Inc. unrelated to Lens.";

export const Profile = () => {
  const { track, trackError } = useAnalytics();
  const { profile, updateProfile } = useContext(ProfileContext);
  const {
    uploadAvatar,
    loading: uploadingAvatar,
    error: uploadAvatarError,
    response: avatarUploadResult,
  } = useUploadAvatar();
  const lensPlatformClient = useLensPlatformClient();
  const [profileError, setProfileError] = useState<null | string>(null);
  const [profileSuccessMessage, setProfileSuccessMessage] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const flashDuration = 2000;
  const [emailCommunicationSwitches, setEmailCommunicationSwitches] = useState<Array<SwitchValue>>();
  const {
    userCommunicationPreferences,
    errorUpdateUserCommunictaionPreferences,
    updateUserCommunicationPreferences,
    refetchUserCommunicationPreferences,
  } = useUserCommunicationPreferences();

  // Store form fields values and validation status
  const [fields, setField] = useReducer(valueReducer, {
    firstName: { value: "" },
    lastName: { value: "" },
    company: { value: "" },
  });

  const formValid = useMemo(() => {
    const fieldValues = Object.values(fields);

    return Boolean(fieldValues.length) && fieldValues.every((v) => v.valid);
  }, [fields]);

  // user attributes returned from get endpoint have a different interface comparing to update endpoint.
  // TODO: Update user attributes to the same format for both endpoints.
  useEffect(() => {
    if (!profile) {
      return;
    }

    const company = profile.userAttributes?.find((attribute) => attribute.name === "company");

    setField({ key: "company", value: company ? company.value : "", valid: Boolean(company) });
    setField({ key: "firstName", value: profile.firstName || "", valid: true });
    setField({ key: "lastName", value: profile.lastName || "", valid: true });
  }, [profile]);

  useEffect(() => {
    setEmailCommunicationSwitches([
      {
        id: "onboarding",
        label: onboardingLabel,
        description: onboardingDescription,
        checked: userCommunicationPreferences.onboarding,
      },
      {
        id: "marketing",
        label: marketingLabel,
        description: marketingDescription,
        checked: userCommunicationPreferences.marketing,
      },
    ]);
  }, [userCommunicationPreferences]);

  const handleSwitchChange = useCallback(
    async (nextSwitches: Array<SwitchValue>) => {
      const previousSwitches = emailCommunicationSwitches || [];

      setEmailCommunicationSwitches(nextSwitches);

      if (profile?.username) {
        try {
          await updateUserCommunicationPreferences({
            onboarding: nextSwitches[0].checked,
            marketing: nextSwitches[1].checked,
          });
        } catch {
          setTimeout(() => {
            // revert switch to previous state
            setEmailCommunicationSwitches(previousSwitches);
          }, 500);
        }
      }
    },
    [emailCommunicationSwitches, profile?.username, updateUserCommunicationPreferences],
  );

  const handleUploadAvatar = async (file: File) => {
    if (profile && profile.username && file) {
      const uploaded = await uploadAvatar(file);

      if (uploaded) {
        updateProfile();
      }

      return uploaded;
    }

    return undefined;
  };

  const handleAvatarError = (error: Error) => {
    setProfileError(error?.message);
  };

  const saveProfile = async () => {
    if (profile && profile.username && formValid) {
      const updatedProfile = { ...profile };

      // API doesn't allow these fields.
      delete updatedProfile.userAttributes;
      delete updatedProfile.fullname;
      delete updatedProfile.companyEmailDomain;

      try {
        // Clear error and success messages first
        setProfileSuccessMessage(null);
        setProfileError(null);

        setIsLoading(true);

        await lensPlatformClient.user.updateOne(profile.username, {
          ...updatedProfile,
          attributes: {
            company: fields.company.value,
          },
          firstName: fields.firstName.value,
          lastName: fields.lastName.value,
        });

        setProfileSuccessMessage("Profile updated");

        updateProfile();

        track("Profile updated");
        setIsLoading(false);

        // remove success test
        setTimeout(() => {
          setProfileSuccessMessage(null);
        }, flashDuration);
      } catch (error: unknown) {
        setIsLoading(false);

        trackError("Profile update failed");

        if (error instanceof Error) {
          const fields: { [key: string]: string } = {
            "attributes.company": "Company",
            firstName: "First name",
            lastName: "Last name",
          };
          const errors = (Array.isArray(error.message) ? error.message : [error.message]).map((message) => {
            const match = message.match(/([a-zA-Z.]*) should not be empty$/);

            if (match) {
              const key: string = match[1];

              return message.replace(key, fields[key] || "");
            }

            return message;
          });

          setProfileError(errors.join("\n"));
        } else {
          setProfileError("Something went wrong");
        }
      }
    }
  };

  return (
    <Layout className="bg-secondary" profile={profile}>
      {profile && (
        <>
          {(profileError || uploadAvatarError || errorUpdateUserCommunictaionPreferences) && (
            <Notification
              flashDuration={flashDuration}
              level="error"
              message={profileError || uploadAvatarError?.message || errorUpdateUserCommunictaionPreferences?.message}
              type="flash"
            />
          )}
          {profileSuccessMessage && (
            <Notification
              className={styles.serverResponseMessage}
              flashDuration={flashDuration}
              level="success"
              message={profileSuccessMessage}
              type="flash"
            />
          )}
          {!uploadAvatarError && avatarUploadResult && (
            <Notification
              className={styles.serverResponseMessage}
              flashDuration={flashDuration}
              level="success"
              message="User avatar successfully updated!"
              type="flash"
            />
          )}
          <PageHeader
            title="Profile"
            subtitle="This is your public profile. Information in here may be displayed publicly to other users."
          />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              saveProfile();
            }}
          >
            <PageSection>
              <div className={styles.form}>
                <div>
                  <div className={styles.name}>
                    <FormInput
                      label="First name"
                      value={fields.firstName.value}
                      onChange={(value, valid) => setField({ key: "firstName", value, valid })}
                      name="firstName"
                    />
                    <FormInput
                      label="Last name"
                      value={fields.lastName.value}
                      onChange={(value, valid) => setField({ key: "lastName", value, valid })}
                      name="lastName"
                    />
                  </div>
                  <FormInput
                    label="Company"
                    value={fields.company.value}
                    onChange={(value, valid) => setField({ key: "company", value, valid })}
                    name="company"
                    required
                  />
                </div>
                <AvatarFormComponent
                  onAvatarError={handleAvatarError}
                  user={profile}
                  loading={uploadingAvatar}
                  onAvatarUpload={handleUploadAvatar}
                />
              </div>
              <p className={styles.disclaimer}>
                Personally Identifiable Information (PII) on this page is optional and can be deleted at any time, and
                by filling them out, you&apos;re giving us consent to share this data wherever your user profile
                appears. Please see our privacy policy to learn more about how we use this information.
              </p>
              <PageAction
                label="Update Profile"
                buttonProps={{
                  loading: isLoading,
                  id: "update-profile",
                  buttonType: "submit",
                  disabled: !formValid,
                }}
              ></PageAction>
            </PageSection>
            <PageSection className={styles.communicationPreferences}>
              <PageHeader title={emailCommunicationTitle} subtitle={emailCommunicationDescription} />
              <FormSwitchGroup label="" values={emailCommunicationSwitches || []} onChange={handleSwitchChange} />
            </PageSection>
          </form>
        </>
      )}
    </Layout>
  );
};
